import React from 'react'
import Head from 'next/head'
import type { AppProps } from 'next/app'
import Script from 'next/script'
import { v4 as uuid } from 'uuid'
import isPropValid from '@emotion/is-prop-valid'
import { StyleSheetManager } from 'styled-components'
import { window } from '@mtsdengi/ui-kit'
import { TGtmConfig, TPageProps } from './types'
import { MTS_MONEY_DEFAULT_TITLE, MTS_MONEY_DEFAULT_DESCRIPTION } from './constants'
/**
 * TODO временно, чтоб стили типпи подхватывались по МФ
 */
import 'tippy.js/index.css'

/**
 * [Документация styled-component]{@link https://styled-components.com/docs/faqs#shouldforwardprop-is-no-longer-provided-by-default}
 * @param propName
 * @param target
 */

const shouldForwardProp = (propName: string, target) => (typeof target === 'string' ? isPropValid(propName) : true)

export const App = ({ Component, pageProps }: AppProps<TPageProps>) => {
  const tagManagerArgs: TGtmConfig = {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    mtmId: process.env.NEXT_PUBLIC_MTM_ID,
  }
  const instanaKey = process.env.NEXT_PUBLIC_INSTANA_KEY
  const { gtmId, mtmId } = tagManagerArgs
  const {
    title = MTS_MONEY_DEFAULT_TITLE,
    description = MTS_MONEY_DEFAULT_DESCRIPTION,
    url,
    image,
    keywords,
    robots,
    ogType,
    twitterImage,
    appleItunesApp,
    googlePlayApp,
    fbAppID,
    fbImage,
  } = pageProps.meta || {}

  const GTM_SCRIPT = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${gtmId}')`

  const MTM_SCRIPT = ` (function (w, d, s, l, u) {
    w[l] = w[l] || [];
    w[l].push({ 'mtm.start': new Date().getTime(), event: 'mtm.js' });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '?l=' + l : '';
    j.async = true;
    j.src = 'https://tag.a.mts.ru/container/' + u + '/tag.js' + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${mtmId}');`

  const INSTANA_SCRIPT = `(function(s,t,a,n){s[t]||(s[t]=a,n=s[a]=function(){n.q.push(arguments)},
  n.q=[],n.v=2,n.l=1*new Date)})(window,"InstanaEumObject","ineum");
  ineum('reportingUrl', '/eum/');
  ineum('key', '${instanaKey}');
  ineum('trackSessions');`

  const INSTANA_SCRIPT_URL = '/eum/eum.min.js'

  const GIB_SCRIPT_URL = '/static/gib/gib.js'
  const handleLoadGib = () => {
    if (typeof window !== 'undefined') {
      window.gib.init({
        cid: process.env.NEXT_PUBLIC_GIB_CID,
        backUrl: '/portal-frontend/statistic',
        forceFirstAlive: true,
        cookiesDomain: JSON.parse(process.env.NEXT_PUBLIC_GIB_COOKIES_DOMAIN),
      })
      window.gib.setAuthStatus(window.gib.IS_GUEST)
      window.gibSessionId = uuid()
      window.gib.setSessionID(window.gibSessionId)
    }
  }

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        <link rel="shortcut icon" href="/static/main-page/icons/favicon.ico" />
        <link rel="apple-touch-icon" href="/static/main-page/icons/apple-touch-icon.png" />
        <link rel="icon" href="/static/main-page/icons/icon.svg" type="image/svg+xml" />
        <link rel="manifest" href="/static/main-page/manifest.webmanifest" />
        <link rel="mask-icon" href="/static/main-page/icons/icon.svg" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        {robots && <meta name="robots" content={robots} />}
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        {image && <meta name="image" content={image} />}
        {url && <link rel="canonical" href={url} />}
        {keywords && <meta name="keywords" content={keywords} />}

        {url && <meta property="og:url" content={url} />}
        {title && <meta property="og:title" content={title} />}
        {description && <meta property="og:description" content={description} />}
        {(fbImage || image) && <meta property="og:image" content={fbImage || image} />}
        {ogType && <meta property="og:type" content={ogType} />}
        {fbAppID && <meta property="fb:app_id" content={fbAppID} />}

        {title && <meta name="twitter:title" content={title} />}
        {description && <meta name="twitter:description" content={description} />}
        {(twitterImage || image) && <meta name="twitter:image" content={twitterImage || image} />}
        {googlePlayApp && <meta name="google-play-app" content={googlePlayApp} />}
        {appleItunesApp && <meta name="apple-itunes-app" content={appleItunesApp} />}
      </Head>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <Component {...pageProps} />
      </StyleSheetManager>
      {!pageProps.isDisableMTM && <Script dangerouslySetInnerHTML={{ __html: MTM_SCRIPT }} id="mtmScript" />}
      {!pageProps.isDisableGib && (
        <Script defer crossOrigin="anonymous" src={GIB_SCRIPT_URL} id="gibScript" onLoad={handleLoadGib} />
      )}
      {!pageProps.isDisableGTM && <Script dangerouslySetInnerHTML={{ __html: GTM_SCRIPT }} id="gtmScript" />}
      {pageProps?.microMarkup?.map((microMarkup: Record<string, unknown>) => (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(microMarkup) }} />
      ))}
      {instanaKey && (
        <>
          <Script id="instana" dangerouslySetInnerHTML={{ __html: INSTANA_SCRIPT }} />
          <Script defer crossOrigin="anonymous" src={INSTANA_SCRIPT_URL} />
        </>
      )}
    </>
  )
}
